import React, { ReactElement, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { ApiService } from "../../services/api.service";
import { htmlToPdf } from "convert-to-pdf";
import {
  currencySelector,
  FormatBasePrice,
  FormatDate,
  FormatGeneralPrice,
  FormatMoney,
} from "../../helpers";
import ButtonLoaderSmall from "../ButtonLoaderSmall";
import { ItemsContext } from "../../context/testCartContext";
import fs from "fs";
interface Order {
  saleID: number;
  userGuid: string;
  saleGBPTotal: number;
  saleEuroTotal: number;
  saleDate: string;
  saleComplete: number;
  saleDespatch: number;
  cartGuid: string;
  deliveryID: number;
  currencyID: number;
  poNumber: string;
  discountApplied: number;
  deliveryAddress: string;
  ProductsBought: string;
  discountcouponused: string;
}

interface User {
  userGuid: string;
  userFirstname: string;
  userHouseNo: string;
  userSurname: string;
}

function SaleComplete(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [saleItems, setSaleItems] = useState([]);
  const [userGuid, setUserGuid] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadResponse, setUploadResponse] = useState("");

  const apiService = new ApiService();

  const onFileChange = (e: any) => {
    // Update the state
    setSelectedFile(e.target.files[0]);
  };

  const FinaliseTotal = (total: any, deliveryCharge: any, discount: any) => {
    var newTotal = total;
    var discCalc = discount;

    var calcTotal = newTotal + deliveryCharge + discCalc;

    return FormatGeneralPrice(calcTotal);
  };

  const [order, setOrder] = useState<Order>({
    saleID: 0,
    userGuid: "",
    saleGBPTotal: 0,
    saleEuroTotal: 0,
    saleDate: "",
    saleComplete: 0,
    saleDespatch: 0,
    cartGuid: "",
    deliveryID: 0,
    currencyID: 0,
    poNumber: "",
    discountApplied: 0,
    deliveryAddress: "",
    ProductsBought: "",
    discountcouponused: "",
  });

  const [user, setUser] = useState<User>({
    userGuid: "",
    userFirstname: "",
    userHouseNo: "",
    userSurname: "",
  });

  const onFileUpload = (e: any) => {
    e.preventDefault();
    setUploading(true);
    // Create an object of formData

    const formData: any = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, order.saleID.toString());

    PostData(formData);
  };

  const PostData = async (data: any) => {
    setUploading(true);
    const response = await apiService.PostFile("api/User/UploadPO", data);

    if (response.ok) {
      setUploadResponse("Your Purchase Order has been uploaded");
      setUploadComplete(true);
    } else {
      setUploadResponse("Your Purchase Order has not been uploaded");
    }
    setUploading(false);
  };

  const fetchOrder = async (e: number) => {
    const response = await apiService.getOrderByID(e);
    setOrder(response);
    setUserGuid(response.userGuid);
    fetchUser(response.userGuid);
    checkFileExists(response.saleID);
  };

  const fetchSaleItems = async (e: number) => {
    const response = await apiService.getSaleItemsByID(e);
    setSaleItems(response);
    setDeliveryCharge(response[0].deliveryCharge);
  };

  const fetchUser = async (e: string) => {
    const response = await apiService.getUserByID(e);
    setUser(response);
  };

  const checkFileExists = async (id: number) => {
    const path = id;
    const response = await apiService.checkFile(path);
    setFileExists(response);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrder(Number(id));
    fetchSaleItems(Number(id));
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
            <div className="d-flex mt-4 pb-4 justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1>Thanks for placing your order MA00{order.saleID}</h1>
            </div>

            <div className="container">
              <div
                className="page-header"
                style={{ textAlign: "center" }}
              ></div>

              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center">
                        <img
                          src="https://printproducts.markandy.com/imgs/ma_logo.png"
                          style={{ width: "25%" }}
                          alt="text"
                        ></img>
                        <hr></hr>
                        <h4>MA00{order.saleID}</h4>
                        <p>{FormatDate(new Date(order.saleDate))}</p>
                        <p></p>
                      </div>

                      <hr></hr>

                      <div className="row">
                        <table
                          className="table table-bordered"
                          style={{ textAlign: "center" }}
                        >
                          <tr style={{ fontWeight: "bold" }}>
                            <td style={{ width: "33%" }}>Customer Details</td>

                            <td style={{ width: "33%" }}>Delivery Address</td>

                            <td style={{ width: "33%" }}>Preferences</td>
                          </tr>

                          <tr>
                            <td style={{ width: "33%" }}>
                              {" "}
                              {user.userFirstname} {user.userSurname}
                              <br></br>
                              {user.userHouseNo}
                            </td>

                            <td style={{ width: "33%" }}>
                              {order.deliveryAddress}{" "}
                            </td>

                            <td style={{ width: "33%" }}>
                              {order.discountApplied ? (
                                <div>
                                  <strong>Coupon: </strong>
                                  {FormatGeneralPrice(order.discountApplied)} (
                                  {order.discountcouponused})<br></br>
                                </div>
                              ) : null}
                              <strong>PO:</strong>
                              {fileExists ? (
                                <a
                                  href={`./assets/PO/MA${order.saleID.toString()}/Purchase_Order.pdf`}
                                  onError={() => setFileExists(false)}
                                  target="_blank"
                                >
                                  Purchase Order
                                </a>
                              ) : null}
                              {order.poNumber}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h3 className="text-center">
                            <strong>Order Summary</strong>
                          </h3>

                          <p
                            className="text-center"
                            style={{ padding: "20px" }}
                          >
                            {deliveryCharge === 0
                              ? "*Delivery charges may be calculated and confirmed prior to dispatching of items."
                              : null}
                          </p>
                        </div>
                        <div className="panel-body">
                          <div className="table-responsive">
                            <table className="table table-condensed">
                              <thead>
                                <tr>
                                  <td style={{ width: "60%" }}>
                                    <strong>Item Name</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Price</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Quantity</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>Total</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {saleItems &&
                                  saleItems.map((item: any) => (
                                    <tr>
                                      <td>
                                        <h2>{item.product.productName}</h2>
                                        <p>
                                          {item.serial} {item.machine}
                                          {item.subProductVariantDetails}
                                        </p>
                                        <p> {item.product.productDetails}</p>
                                      </td>

                                      <td className="text-center">
                                        {FormatGeneralPrice(item.salePrice)}
                                      </td>

                                      <td className="text-center">
                                        {item.productQuantity}
                                      </td>
                                      <td className="text-right">
                                        {FormatGeneralPrice(
                                          item.salePrice * item.productQuantity
                                        )}
                                      </td>
                                    </tr>
                                  ))}

                                {order.discountApplied ? (
                                  <tr>
                                    <td className="highrow"></td>
                                    <td className="highrow"></td>
                                    <td className="highrow text-center">
                                      <strong>Discount</strong>
                                    </td>
                                    <td className="highrow text-right">
                                      {order.discountApplied
                                        ? FormatGeneralPrice(
                                            order.discountApplied
                                          )
                                        : 0}
                                    </td>
                                  </tr>
                                ) : null}

                                <tr>
                                  <td className="highrow"></td>
                                  <td className="highrow"></td>
                                  <td className="highrow text-center">
                                    <strong>Sub Total</strong>
                                  </td>
                                  <td className="highrow text-right">
                                    {order.discountApplied
                                      ? FormatGeneralPrice(
                                          order.saleGBPTotal +
                                            order.discountApplied
                                        )
                                      : FormatGeneralPrice(order.saleGBPTotal)}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="highrow"></td>
                                  <td className="highrow"></td>
                                  <td className="highrow text-center">
                                    <strong>
                                      {deliveryCharge !== 0
                                        ? "Delivery"
                                        : "Delivery To Be Advised"}
                                    </strong>
                                  </td>
                                  <td className="highrow text-right">
                                    {FormatGeneralPrice(deliveryCharge)}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="emptyrow"></td>
                                  <td className="emptyrow"></td>
                                  <td className="emptyrow text-center">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="emptyrow text-right">
                                    {FinaliseTotal(
                                      order.saleGBPTotal,
                                      deliveryCharge,
                                      order.discountApplied
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-12"
                      style={{ marginBottom: "15px", textAlign: "center" }}
                    >
                      <div style={{ fontSize: "17px" }}>
                        <h4>Contact Us</h4>
                        <hr />
                      </div>

                      <div className="row">
                        <table
                          className="table"
                          style={{ textAlign: "center" }}
                        >
                          <tr>
                            <td>
                              <b>Mark Andy Europe</b>
                              <br />
                              Queens Avenue
                              <br />
                              Hurdsfield Industrial Estate
                              <br />
                              Macclesfield, <br />
                              Cheshire SK10 2BN
                              <br />
                              England
                              <br />
                              Phone +44 1 625 500 964
                              <br />
                              Fax +44 1 625 501 334
                              <br />
                              <a href="mailto:euspares@markandy.com">
                                euspares@markandy.com
                              </a>{" "}
                              <br />
                              <br />
                              <br />
                            </td>

                            <td>
                              <b>Mark Andy Inc.</b>
                              <br />
                              18081 Chesterfield <br />
                              Airport Road
                              <br />
                              Chesterfield
                              <br />
                              USA
                              <br />
                              MO 63005
                              <br />
                              Tel - 001 636 532 4433
                              <br />
                              USA Toll Free 800 700 6275
                              <br />
                              <a href="mailto:parts@yourfriendinflexo.com">
                                parts@yourfriendinflexo.com
                              </a>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="container p-5 mb-4 bg-light rounded-3">
                    {fileExists ? (
                      <p>Purchase Order Uploaded</p>
                    ) : (
                      <p>Upload a PDF purchase order</p>
                    )}
                    {uploadComplete ? (
                      <a
                        href={`./assets/PO/MA${order.saleID.toString()}/Purchase_Order.pdf`}
                      >
                        Your Purchase Order
                      </a>
                    ) : (
                      <span>
                        <input
                          className="inputfile"
                          type="file"
                          onChange={onFileChange}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={onFileUpload}
                        >
                          {uploading ? (
                            <ButtonLoaderSmall></ButtonLoaderSmall>
                          ) : (
                            <span>Upload a Purchase Order</span>
                          )}
                        </button>
                      </span>
                    )}
                    <hr></hr>

                    {fileExists ? (
                      <a
                        href={`./assets/PO/MA${order.saleID.toString()}/Purchase_Order.pdf`}
                        onError={() => setFileExists(false)}
                        target="_blank"
                      >
                        View Purchase Order
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default SaleComplete;
