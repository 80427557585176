import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function TesaTape(): ReactElement {
  const softPrintSTM = "D3A59599-B672-432C-ADAC-D48263C1FDBD";
  const softPrintTP = "0945AFF5-4F5E-4ECC-8D6C-05B7B1341E78";
  const softPrintSEC = "BD022D06-C5EB-40C5-ADB3-7C050CEC05C2";
  const softPrintFlex = "1E75C0FB-FA3A-4D72-BBB8-1ED2E238552B";
  const otherTesa = "B9A7E095-8072-4137-9E35-AEB178B058B4";

  return (
    <div>
      <img
        src={window.location.origin + `/imgs/tape-header2.jpg`}
        style={{ width: "100%", marginTop: "-1px" }}
        alt="text"
      ></img>

      <div className="container">
        <Container>
          <div className="row">
            <div className="col-lg-12">
              <div style={{ marginTop: "40px" }}>
                <h1>
                  Tapes: Plate Mounting Tapes, Splicing Tapes, Stickyback Tapes,
                  and Specialty Tapes
                </h1>
                <hr />
              </div>
            </div>

            <div className="col-lg-8">
              <p>
                To assist with optimized print results and ensure process
                efficiency, Mark Andy Print Products can supply your pressroom
                with a variety of tapes in both standard and custom-order sizes
                with a variety of thicknesses, offering solutions for all
                application demands.
              </p>

              <ul>
                <li>
                  Reduce press downtime and increase efficiency with the correct
                  plate mounting tapes for your applications.
                </li>

                <li>
                  The right choice of stickyback mounting tape can improve your
                  print quality, eliminate plate lift issues and promote easy
                  plate repositioning.
                </li>

                <li>
                  New advancements in splicing tape technology eliminates
                  web-breaks and tears. Technical experts ready to consult your
                  company on your workflow’s tape needs to achieve consistent
                  print quality.
                </li>

                <li>
                  Stocked inventory of custom slit rolls. Great for press or job
                  specific applications!
                </li>
              </ul>
              <br />

              <h4>Achieve Higher Output with the Right Mounting Tapes</h4>

              <p>
                Tesa Softprint® assortment provides distinctive produce lines
                for flexo printers to fulfill any requirement. The product
                matrix will help you find a optimized product line to match your
                process requirements.
              </p>
            </div>

            <div className="col-lg-4">
              <div className="videoWrapper" style={{ marginBottom: "15px" }}>
                <iframe
                  src="https://www.youtube.com/embed/UGIxM4y6svs"
                  frameBorder="0"
                  allowFullScreen
                  title="tesa tape"
                ></iframe>
              </div>
            </div>
          </div>
        </Container>

        <div className="row align-items-center my-5">
          <div className="col-lg-6">
            <img
              className="img-thumbnail"
              src="https://printproducts.markandy.com//images/SIM-PWP_splicing-016_72dpi.jpg"
              width="100%"
              alt=""
            />

            <h2>Improve your print quality</h2>

            <p>
              The right choice of stickyback mounting tape can eliminate plate
              lift issues and promote easy plate repositioning.
            </p>
          </div>

          <div className="col-lg-6">
            <img
              className="img-thumbnail"
              src="https://printproducts.markandy.com//images/xSIM-PWP_splicing-016_72dpi.jpg"
              width="100%"
              alt=""
            />

            <h2>Eliminate web-breaks and tears</h2>

            <p>
              New advancements in splicing tape technology eliminates tears.
              Technical experts ready to consult your company on your workflow’s
              tape needs to achieve consistent print quality.
            </p>
          </div>

          <div className="clearfix"></div>

          <div className="container">
            <div className="row">
              <br />
              <br />
              <hr />
              <br />
              <br />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <Link to={`/search?s=${softPrintSTM.toUpperCase()}`}>
                  <img
                    src="https://printproducts.markandy.com//images/tesa-stm.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  ></img>
                </Link>
              </div>

              <div className="col-md-2">
                <Link to={`/search?s=${softPrintTP.toUpperCase()}`}>
                  {" "}
                  <img
                    src="https://printproducts.markandy.com//images/tesa-tp.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  ></img>
                </Link>
              </div>

              <div className="col-md-2">
                <Link to={`/search?s=${softPrintSEC.toUpperCase()}`}>
                  <img
                    src="https://printproducts.markandy.com//images/tesa-sec.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  ></img>
                </Link>
              </div>

              <div className="col-md-2">
                <Link to={`/search?s=${softPrintFlex.toUpperCase()}`}>
                  <img
                    src="https://printproducts.markandy.com//images/tesa-flex.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  ></img>
                </Link>
              </div>

              <div className="col-md-2">
                <Link to={`/search?s=${otherTesa.toUpperCase()}`}>
                  <img
                    src="https://printproducts.markandy.com//images/other-tesa.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  />
                </Link>
              </div>

              <div className="col-md-2">
                <a href="https://printproducts.markandy.com/downloads/flyer.pdf">
                  <img
                    src="https://printproducts.markandy.com//images/tesa-range.jpg"
                    className="img-thumbnail"
                    width="100%"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="clearfix"></div>

            <hr />

            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <a
                    href="http://www.markandy.com/wp-content/uploads/2018/09/tesa_White-Paper-on-Mounting-Tape.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={window.location.origin + `/imgs/box1.jpg`}
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </a>
                  <a
                    href="http://info.markandy.com/tesa-softprint-secure-mark-andy-print-products"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={window.location.origin + `/imgs/box2.jpg`}
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </a>
                  <a
                    href="http://www.markandy.com/wp-content/uploads/2018/09/tesa_Plate_Mounting_SOP.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={window.location.origin + `/imgs/box3.jpg`}
                      style={{ width: "100%", marginTop: "-1px" }}
                      alt="text"
                    ></img>
                  </a>
                </div>

                <div className="col-md-9">
                  <img
                    src={window.location.origin + `/imgs/tesa-tape-range.jpg`}
                    style={{ width: "100%", marginTop: "-1px" }}
                    alt="text"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TesaTape;
