import React, { ReactElement, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { LoginContext } from "../../../context/categoryContext";
import { currencySelector, FormatDate } from "../../../helpers";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { Badge } from "react-bootstrap";

interface Order {
  saleID: number;
  userGuid: string;
  saleGBPTotal: number;
  saleEuroTotal: number;
  saleDate: string;
  saleComplete: number;
  saleDespatch: number;
  cartGuid: string;
  deliveryID: number;
  currencyID: number;
  PONumber: string;
  discountApplied: number;
  deliveryAddress: string;
  ProductsBought: string;
  discountcouponused: string;
}

interface User {
  userFirstname: string;
  userSurname: string;
  userGuid: string;
  userHouseNo: string;
  userStreet: string;
  userPostcode: string;
  userCounty: string;
  userCity: string;
  Country: number;
}

function OrderView(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const [saleItems, setSaleItems] = useState([]);
  const [userGuid, setUserGuid] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [order, setOrder] = useState<Order>({
    saleID: 0,
    userGuid: "",
    saleGBPTotal: 0,
    saleEuroTotal: 0,
    saleDate: "",
    saleComplete: 0,
    saleDespatch: 0,
    cartGuid: "",
    deliveryID: 0,
    currencyID: 0,
    PONumber: "",
    discountApplied: 0,
    deliveryAddress: "",
    ProductsBought: "",
    discountcouponused: "",
  });

  const [user, setUser] = useState<User>({
    userGuid: "",
    userFirstname: "",
    userSurname: "",
    userHouseNo: "",
    userStreet: "",
    userPostcode: "",
    userCounty: "",
    userCity: "",
    Country: 0,
  });

  const apiService = new ApiService();

  const fetchOrder = async (e: number) => {
    const response = await apiService.getOrderByID(e);
    setOrder(response);
    setUserGuid(response.userGuid);
    fetchUser(String(response.userGuid));
  };

  const fetchSaleItems = async (e: number) => {
    const response = await apiService.getSaleItemsByID(e);
    setSaleItems(response);
    setDeliveryCharge(response[0].deliveryCharge);
  };

  const fetchUser = async (e: string) => {
    const response = await apiService.getUserByID(e);
    setUser(response);
  };

  useEffect(() => {
    fetchOrder(Number(id));
    fetchSaleItems(Number(id));
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
              <h1 className="h2"></h1>
              {/* <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Export
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                >
                  <span
                    data-feather="calendar"
                    className="align-text-bottom"
                  ></span>
                  This week
                </button>
              </div> */}
            </div>

            <div className="container">
              <div className="page-header" style={{ textAlign: "center" }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }}></div>
                <h1>
                  <img
                    src="https://printproducts.markandy.com/imgs/ma_logo.png"
                    style={{ width: "30%" }}
                    alt="text"
                  ></img>
                </h1>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      <h2>MA00{order.saleID}</h2>
                      <p>{FormatDate(new Date(order.saleDate))}</p>
                    </div>

                    <hr></hr>

                    <div className="row">
                      <table
                        className="table table-bordered"
                        style={{ textAlign: "center" }}
                      >
                        <tr style={{ fontWeight: "bold" }}>
                          <td style={{ width: "33%" }}>Customer Details</td>

                          <td style={{ width: "33%" }}>Delivery Address</td>

                          <td style={{ width: "33%" }}>Preferences</td>
                        </tr>

                        <tr>
                          <td style={{ width: "33%" }}>
                            {user.userFirstname} {user.userSurname}
                            <br></br>
                            {user.userHouseNo}
                            <br></br>
                            {user.userStreet}
                            <br></br>
                            {user.userCity}
                            <br></br>
                            {user.userCounty}
                            <br></br>
                            {user.userPostcode}
                            <br></br>
                          </td>

                          <td style={{ width: "33%" }}>
                            {order.deliveryAddress}{" "}
                          </td>

                          <td style={{ width: "33%" }}>
                            <strong>Coupon: </strong>
                            {currencySelector(
                              order.currencyID,
                              order.discountApplied
                            )}
                            ({order.discountcouponused})
                            <br />
                            <strong>PO: </strong>
                            {order.PONumber}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="text-center">
                          <strong>Order Summary</strong>
                        </h3>

                        <p className="text-center" style={{ padding: "20px" }}>
                          {deliveryCharge === 0
                            ? "*Delivery charges may be calculated and confirmed prior to dispatching of items."
                            : null}
                        </p>
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-condensed">
                            <thead>
                              <tr>
                                <td style={{ width: "60%" }}>
                                  <strong>Item Name</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Price</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Quantity</strong>
                                </td>
                                <td className="text-right">
                                  <strong>Total</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {saleItems &&
                                saleItems.map((item: any) => (
                                  <tr>
                                    <td>
                                      <strong>
                                        <h2 style={{ display: "inline" }}>
                                          {item.product.productName}
                                        </h2>
                                      </strong>
                                      {item.serial} {item.machine}
                                      <p>
                                        {item.serial} {item.machine}
                                        {item.subProductVariantDetails}
                                      </p>
                                      <p>{item.product.productDetails}</p>
                                    </td>

                                    <td className="text-center">
                                      {currencySelector(
                                        order.currencyID,
                                        item.salePrice
                                      )}
                                    </td>

                                    <td className="text-center">
                                      {item.productQuantity}
                                    </td>
                                    <td className="text-right">
                                      {currencySelector(
                                        order.currencyID,
                                        item.salePrice * item.productQuantity
                                      )}
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>Discount</strong>
                                </td>
                                <td className="highrow text-right">
                                  {order.discountApplied
                                    ? currencySelector(
                                        order.currencyID,
                                        order.discountApplied
                                      )
                                    : currencySelector(order.currencyID, 0)}
                                </td>
                              </tr>

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>Sub Total</strong>
                                </td>
                                <td className="highrow text-right">
                                  {currencySelector(
                                    order.currencyID,
                                    order.saleGBPTotal
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td className="highrow"></td>
                                <td className="highrow"></td>
                                <td className="highrow text-center">
                                  <strong>
                                    {deliveryCharge !== 0
                                      ? "Delivery"
                                      : "Delivery To Be Advised"}
                                  </strong>
                                </td>
                                <td className="highrow text-right">
                                  {currencySelector(
                                    order.currencyID,
                                    deliveryCharge
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td className="emptyrow"></td>
                                <td className="emptyrow"></td>
                                <td className="emptyrow text-center">
                                  <strong>Total</strong>
                                </td>
                                <td className="emptyrow text-right">
                                  {currencySelector(
                                    order.currencyID,
                                    deliveryCharge + order.saleGBPTotal
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  >
                    <div style={{ fontSize: "17px" }}>
                      <h4>Contact Us</h4>
                      <hr />
                    </div>

                    <div className="row">
                      <table className="table" style={{ textAlign: "center" }}>
                        <tr>
                          <td>
                            <b>Mark Andy Europe</b>
                            <br />
                            Queens Avenue
                            <br />
                            Hurdsfield Industrial Estate
                            <br />
                            Macclesfield, <br />
                            Cheshire SK10 2BN
                            <br />
                            England
                            <br />
                            Phone +44 1 625 500 964
                            <br />
                            Fax +44 1 625 501 334
                            <br />
                            <a href="mailto:euspares@markandy.com">
                              euspares@markandy.com
                            </a>{" "}
                            <br />
                            <br />
                            <br />
                          </td>

                          <td>
                            <b>Mark Andy Inc.</b>
                            <br />
                            18081 Chesterfield <br />
                            Airport Road
                            <br />
                            Chesterfield
                            <br />
                            USA
                            <br />
                            MO 63005
                            <br />
                            Tel - 001 636 532 4433
                            <br />
                            USA Toll Free 800 700 6275
                            <br />
                            <a href="mailto:parts@yourfriendinflexo.com">
                              parts@yourfriendinflexo.com
                            </a>
                          </td>
                        </tr>
                      </table>

                      <div className="col-6 col-md-6 col-lg-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default OrderView;
