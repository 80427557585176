import React, { ReactElement } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Popular(): ReactElement {
  const tesa1 = "D3A59599-B672-432C-ADAC-D48263C1FDBD";
  const tesa2 = "E6A4A234-B1D7-4038-87CF-D208DFB14DD2";
  const tesa3 = "BD022D06-C5EB-40C5-ADB3-7C050CEC05C2";
  const tesa4 = "1E75C0FB-FA3A-4D72-BBB8-1ED2E238552B";

  return (
    <section className="popular-categories section-padding mt-15">
      <div className="wow fadeIn animated">
        <h3 className="section-title mb-20">
          <span>Popular</span> Categories
        </h3>

        <img
          src={window.location.origin + `/imgs/tape-header2.jpg`}
          alt="text"
          style={{ width: "100%", marginBottom: "20px" }}
        ></img>

        <div className="carausel-6-columns-cover position-relative">
          <Row>
            <Col>
              <div className="card-1">
                <figure className=" img-hover-scale overflow-hidden">
                  <Link to={`/search?s=${tesa1.toUpperCase()}`}>
                    <img
                      src={`https://printproducts.markandy.com/imgs/tesa-stm.jpg`}
                      alt="text"
                    ></img>
                  </Link>
                </figure>
              </div>
            </Col>
            <Col>
              <div className="card-1">
                <figure className=" img-hover-scale overflow-hidden">
                  <Link to={`/search?s=${tesa2.toUpperCase()}`}>
                    {" "}
                    <img
                      src={`https://printproducts.markandy.com/imgs/tesa-tp.jpg`}
                      alt="text"
                    ></img>
                  </Link>{" "}
                </figure>
              </div>
            </Col>
            <Col>
              <div className="card-1">
                <figure className=" img-hover-scale overflow-hidden">
                  <Link to={`/search?s=${tesa3.toUpperCase()}`}>
                    <img
                      src={`https://printproducts.markandy.com/imgs/tesa-sec.jpg`}
                      alt="text"
                    ></img>
                  </Link>{" "}
                </figure>
              </div>
            </Col>
            <Col>
              <div className="card-1">
                <figure className=" img-hover-scale overflow-hidden">
                  <Link to={`/search?s=${tesa4.toUpperCase()}`}>
                    <img
                      src={`https://printproducts.markandy.com/imgs/tesa-flex.jpg`}
                      alt="text"
                    ></img>
                  </Link>{" "}
                </figure>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
export default Popular;
